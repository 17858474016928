
    <template>
      <section class="content element-doc">
        <h2>Progress 进度条</h2>
<p>用于展示操作进度，告知用户当前状态和预期。</p>
<h3>线形进度条</h3>
<demo-block>
        <div><p>Progress 组件设置<code>percentage</code>属性即可，表示进度条对应的百分比，<strong>必填</strong>，必须在 0-100。通过 <code>format</code> 属性来指定进度条文字内容。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-progress :percentage=&quot;50&quot;&gt;&lt;/el-progress&gt;
&lt;el-progress :percentage=&quot;100&quot; :format=&quot;format&quot;&gt;&lt;/el-progress&gt;
&lt;el-progress :percentage=&quot;100&quot; status=&quot;success&quot;&gt;&lt;/el-progress&gt;
&lt;el-progress :percentage=&quot;100&quot; status=&quot;warning&quot;&gt;&lt;/el-progress&gt;
&lt;el-progress :percentage=&quot;50&quot; status=&quot;exception&quot;&gt;&lt;/el-progress&gt;

&lt;script&gt;
  export default {
    setup() {
      const format = (percentage) =&gt; {
        return percentage === 100 ? '满' : `${percentage}%`
      }
      return { format }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>百分比内显</h3>
<p>百分比不占用额外控件，适用于文件上传等场景。</p>
<demo-block>
        <div><p>Progress 组件可通过 <code>stroke-width</code> 属性更改进度条的高度，并可通过 <code>text-inside</code> 属性来将进度条描述置于进度条内部。</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-progress
  :text-inside=&quot;true&quot;
  :stroke-width=&quot;26&quot;
  :percentage=&quot;70&quot;
&gt;&lt;/el-progress&gt;
&lt;el-progress
  :text-inside=&quot;true&quot;
  :stroke-width=&quot;24&quot;
  :percentage=&quot;100&quot;
  status=&quot;success&quot;
&gt;&lt;/el-progress&gt;
&lt;el-progress
  :text-inside=&quot;true&quot;
  :stroke-width=&quot;22&quot;
  :percentage=&quot;80&quot;
  status=&quot;warning&quot;
&gt;&lt;/el-progress&gt;
&lt;el-progress
  :text-inside=&quot;true&quot;
  :stroke-width=&quot;20&quot;
  :percentage=&quot;50&quot;
  status=&quot;exception&quot;
&gt;&lt;/el-progress&gt;
</code></pre></template></demo-block><h3>自定义颜色</h3>
<p>可以通过 <code>color</code> 设置进度条的颜色，<code>color</code> 可以接受颜色字符串，函数和数组。</p>
<demo-block>
        
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-progress :percentage=&quot;percentage&quot; :color=&quot;customColor&quot;&gt;&lt;/el-progress&gt;

&lt;el-progress :percentage=&quot;percentage&quot; :color=&quot;customColorMethod&quot;&gt;&lt;/el-progress&gt;

&lt;el-progress :percentage=&quot;percentage&quot; :color=&quot;customColors&quot;&gt;&lt;/el-progress&gt;
&lt;div&gt;
  &lt;el-button-group&gt;
    &lt;el-button icon=&quot;el-icon-minus&quot; @click=&quot;decrease&quot;&gt;&lt;/el-button&gt;
    &lt;el-button icon=&quot;el-icon-plus&quot; @click=&quot;increase&quot;&gt;&lt;/el-button&gt;
  &lt;/el-button-group&gt;
&lt;/div&gt;

&lt;script&gt;
  import { ref, reactive, getCurrentInstance, toRefs } from 'vue'
  export default {
    setup() {
      const self = getCurrentInstance().ctx
      const percentage = ref(20)
      const customColor = ref('#409eff')
      const data = reactive({
        customColors: [
          { color: '#f56c6c', percentage: 20 },
          { color: '#e6a23c', percentage: 40 },
          { color: '#5cb87a', percentage: 60 },
          { color: '#1989fa', percentage: 80 },
          { color: '#6f7ad3', percentage: 100 }
        ]
      })

      const customColorMethod = (percentage) =&gt; {
        if (percentage &lt; 30) {
          return '#909399'
        } else if (percentage &lt; 70) {
          return '#e6a23c'
        } else {
          return '#67c23a'
        }
      }

      const increase = () =&gt; {
        percentage.value += 10
        if (percentage.value &gt; 100) {
          percentage.value = 100
        }
      }

      const decrease = () =&gt; {
        percentage.value -= 10
        if (percentage.value &lt; 0) {
          percentage.value = 0
        }
      }

      return {
        percentage,
        customColor,
        ...toRefs(data),
        customColorMethod,
        increase,
        decrease
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>环形进度条</h3>
<p>Progress 组件可通过 <code>type</code> 属性来指定使用环形进度条，在环形进度条中，还可以通过 <code>width</code> 属性来设置其大小。</p>
<demo-block>
        
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-progress type=&quot;circle&quot; :percentage=&quot;0&quot;&gt;&lt;/el-progress&gt;
&lt;el-progress type=&quot;circle&quot; :percentage=&quot;25&quot;&gt;&lt;/el-progress&gt;
&lt;el-progress type=&quot;circle&quot; :percentage=&quot;100&quot; status=&quot;success&quot;&gt;&lt;/el-progress&gt;
&lt;el-progress type=&quot;circle&quot; :percentage=&quot;70&quot; status=&quot;warning&quot;&gt;&lt;/el-progress&gt;
&lt;el-progress type=&quot;circle&quot; :percentage=&quot;50&quot; status=&quot;exception&quot;&gt;&lt;/el-progress&gt;
</code></pre></template></demo-block><h3>仪表盘形进度条</h3>
<demo-block>
        <div><p>通过 <code>type</code> 属性来指定使用仪表盘形进度条。</p>
</div>
        <template #source><element-demo4 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-progress
  type=&quot;dashboard&quot;
  :percentage=&quot;percentage&quot;
  :color=&quot;colors&quot;
&gt;&lt;/el-progress&gt;
&lt;div&gt;
  &lt;el-button-group&gt;
    &lt;el-button icon=&quot;el-icon-minus&quot; @click=&quot;decrease&quot;&gt;&lt;/el-button&gt;
    &lt;el-button icon=&quot;el-icon-plus&quot; @click=&quot;increase&quot;&gt;&lt;/el-button&gt;
  &lt;/el-button-group&gt;
&lt;/div&gt;

&lt;script&gt;
  import { ref, reactive, getCurrentInstance, toRefs } from 'vue'
  export default {
    setup() {
      const self = getCurrentInstance().ctx
      const percentage = ref(10)
      const data = reactive({
        colors: [
          { color: '#f56c6c', percentage: 20 },
          { color: '#e6a23c', percentage: 40 },
          { color: '#5cb87a', percentage: 60 },
          { color: '#1989fa', percentage: 80 },
          { color: '#6f7ad3', percentage: 100 }
        ]
      })

      const increase = () =&gt; {
        percentage.value += 10
        if (percentage.value &gt; 100) {
          percentage.value = 100
        }
      }

      const decrease = () =&gt; {
        percentage.value -= 10
        if (percentage.value &lt; 0) {
          percentage.value = 0
        }
      }

      return {
        percentage,
        ...toRefs(data),
        increase,
        decrease
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td><strong>percentage</strong></td>
<td><strong>百分比（必填）</strong></td>
<td>number</td>
<td>0-100</td>
<td>0</td>
</tr>
<tr>
<td>type</td>
<td>进度条类型</td>
<td>string</td>
<td>line/circle/dashboard</td>
<td>line</td>
</tr>
<tr>
<td>stroke-width</td>
<td>进度条的宽度，单位 px</td>
<td>number</td>
<td>—</td>
<td>6</td>
</tr>
<tr>
<td>text-inside</td>
<td>进度条显示文字内置在进度条内（只在 type=line 时可用）</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>status</td>
<td>进度条当前状态</td>
<td>string</td>
<td>success/exception/warning</td>
<td>—</td>
</tr>
<tr>
<td>color</td>
<td>进度条背景色（会覆盖 status 状态颜色）</td>
<td>string/function/array</td>
<td>—</td>
<td>''</td>
</tr>
<tr>
<td>width</td>
<td>环形进度条画布宽度（只在 type 为 circle 或 dashboard 时可用）</td>
<td>number</td>
<td></td>
<td>126</td>
</tr>
<tr>
<td>show-text</td>
<td>是否显示进度条文字内容</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>stroke-linecap</td>
<td>circle/dashboard 类型路径两端的形状</td>
<td>string</td>
<td>butt/round/square</td>
<td>round</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_progress = _resolveComponent("el-progress")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_progress, { percentage: 50 }),
    _createVNode(_component_el_progress, {
      percentage: 100,
      format: _ctx.format
    }, null, 8, ["format"]),
    _createVNode(_component_el_progress, {
      percentage: 100,
      status: "success"
    }),
    _createVNode(_component_el_progress, {
      percentage: 100,
      status: "warning"
    }),
    _createVNode(_component_el_progress, {
      percentage: 50,
      status: "exception"
    })
  ], 64))
}
  
    const democomponentExport = {
    setup() {
      const format = (percentage) => {
        return percentage === 100 ? '满' : `${percentage}%`
      }
      return { format }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_progress = _resolveComponent("el-progress")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_progress, {
      "text-inside": true,
      "stroke-width": 26,
      percentage: 70
    }),
    _createVNode(_component_el_progress, {
      "text-inside": true,
      "stroke-width": 24,
      percentage: 100,
      status: "success"
    }),
    _createVNode(_component_el_progress, {
      "text-inside": true,
      "stroke-width": 22,
      percentage: 80,
      status: "warning"
    }),
    _createVNode(_component_el_progress, {
      "text-inside": true,
      "stroke-width": 20,
      percentage: 50,
      status: "exception"
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, withCtx: _withCtx, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_progress = _resolveComponent("el-progress")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_button_group = _resolveComponent("el-button-group")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_progress, {
      percentage: _ctx.percentage,
      color: _ctx.customColor
    }, null, 8, ["percentage", "color"]),
    _createVNode(_component_el_progress, {
      percentage: _ctx.percentage,
      color: _ctx.customColorMethod
    }, null, 8, ["percentage", "color"]),
    _createVNode(_component_el_progress, {
      percentage: _ctx.percentage,
      color: _ctx.customColors
    }, null, 8, ["percentage", "color"]),
    _createVNode("div", null, [
      _createVNode(_component_el_button_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            icon: "el-icon-minus",
            onClick: _ctx.decrease
          }, null, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            icon: "el-icon-plus",
            onClick: _ctx.increase
          }, null, 8, ["onClick"])
        ]),
        _: 1
      })
    ])
  ], 64))
}
  
    
        const { ref, reactive, getCurrentInstance, toRefs } = Vue
        
  const democomponentExport = {
    setup() {
      const self = getCurrentInstance().ctx
      const percentage = ref(20)
      const customColor = ref('#409eff')
      const data = reactive({
        customColors: [
          { color: '#f56c6c', percentage: 20 },
          { color: '#e6a23c', percentage: 40 },
          { color: '#5cb87a', percentage: 60 },
          { color: '#1989fa', percentage: 80 },
          { color: '#6f7ad3', percentage: 100 }
        ]
      })

      const customColorMethod = (percentage) => {
        if (percentage < 30) {
          return '#909399'
        } else if (percentage < 70) {
          return '#e6a23c'
        } else {
          return '#67c23a'
        }
      }

      const increase = () => {
        percentage.value += 10
        if (percentage.value > 100) {
          percentage.value = 100
        }
      }

      const decrease = () => {
        percentage.value -= 10
        if (percentage.value < 0) {
          percentage.value = 0
        }
      }

      return {
        percentage,
        customColor,
        ...toRefs(data),
        customColorMethod,
        increase,
        decrease
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_progress = _resolveComponent("el-progress")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_progress, {
      type: "circle",
      percentage: 0
    }),
    _createVNode(_component_el_progress, {
      type: "circle",
      percentage: 25
    }),
    _createVNode(_component_el_progress, {
      type: "circle",
      percentage: 100,
      status: "success"
    }),
    _createVNode(_component_el_progress, {
      type: "circle",
      percentage: 70,
      status: "warning"
    }),
    _createVNode(_component_el_progress, {
      type: "circle",
      percentage: 50,
      status: "exception"
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo4": (function() {
    
    const { resolveComponent: _resolveComponent, createVNode: _createVNode, withCtx: _withCtx, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_progress = _resolveComponent("el-progress")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_button_group = _resolveComponent("el-button-group")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_progress, {
      type: "dashboard",
      percentage: _ctx.percentage,
      color: _ctx.colors
    }, null, 8, ["percentage", "color"]),
    _createVNode("div", null, [
      _createVNode(_component_el_button_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            icon: "el-icon-minus",
            onClick: _ctx.decrease
          }, null, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            icon: "el-icon-plus",
            onClick: _ctx.increase
          }, null, 8, ["onClick"])
        ]),
        _: 1
      })
    ])
  ], 64))
}
  
    
        const { ref, reactive, getCurrentInstance, toRefs } = Vue
        
  const democomponentExport = {
    setup() {
      const self = getCurrentInstance().ctx
      const percentage = ref(10)
      const data = reactive({
        colors: [
          { color: '#f56c6c', percentage: 20 },
          { color: '#e6a23c', percentage: 40 },
          { color: '#5cb87a', percentage: 60 },
          { color: '#1989fa', percentage: 80 },
          { color: '#6f7ad3', percentage: 100 }
        ]
      })

      const increase = () => {
        percentage.value += 10
        if (percentage.value > 100) {
          percentage.value = 100
        }
      }

      const decrease = () => {
        percentage.value -= 10
        if (percentage.value < 0) {
          percentage.value = 0
        }
      }

      return {
        percentage,
        ...toRefs(data),
        increase,
        decrease
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  